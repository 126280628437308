import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TransactionData } from './api-request';
import { TransactionFees } from './api-response';
import { AppService } from './app.service';
import { StringHelper } from './strings-helper';
import { postTransactionMessage } from './events';

/**
 * Created by Stephen on 10/09/2022.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideMenuInOut', [
      state(
        'in',
        style({
          opacity: 0,
        })
      ),
      state(
        'out',
        style({
          opacity: 1,
          zIndex: 100,
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('400ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'omni-payment-gateway-sdk';
  client = 'zest';
  menuState: string = 'out';
  clientName: string = `${this.client} Payments Limited`

  state = {
    visible: false,
    isInitialized: false,
    completed: false,
    error: false,
  };

  errorMessage!: string;
  appLogo = `api.dev.gateway.com/assets/img/${this.client}-logo.png`;
  showLogo: boolean = false;
  activePaymentTab!: string;

  private setState(key: any, value: any) {
    this.state = { ...this.state, [key]: value };
    this.cd.detectChanges();
  }

  transactionData!: TransactionData;
  amount = 0;
  transactionFees!: TransactionFees;
  reference!: string;

  directDebit: boolean = false;
  visaQR: boolean = false;
  channels: Array<string> = [];
  optionalChannels: Array<string> = [];
  selectedChannels: Array<string> = [];
  currency: string = 'NGN';
  callback: any = {};

  constructor(
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    window.addEventListener('message', this.handleEvent);

  }

  handleEvent = (event: any) => {
    const isNewTransactionEvent =
      typeof event.data === 'object' && event.data.type === 'omni:url';
    if (isNewTransactionEvent) {
      postTransactionMessage('loadedCheckout');
      
      if (event.data?.params) {
        this.transactionData = event.data.params;
        this.channels = event.data.params.channels;
        this.currency = event.data.params.currency;
  
        this.appService.transactionData = this.transactionData;
        this.appService.processData();
        this.setTransaction(event.data.params);
      }
    }
  };

  setTransaction(r: TransactionData) {
    let temp = new Object();
    if (r?.paymentMode) {
      temp = {
        paymentMode: r.paymentMode,
        paymentLinkReference: r.paymentLinkReference,
      };
    }
    if (r?.metadata) {
      temp = { ...temp, metadata: r.metadata };
    }
    if (r?.ref) {
      temp = {...temp, reference: r.ref}
    }
    const data = {
      email: r.email,
      amount: r.amount,
      currency: r.currency,
      ...temp,
    };

    // remove this handler
    window.removeEventListener('message', this.handleEvent);
    this.appService.initializeTransaction(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.selectedChannels = res.data.merchantSelectedTokens;
          this.channels = StringHelper.isEmptyArray(this.selectedChannels) ? this.optionalChannels : this.selectedChannels;
          this.reference = res.data.reference;
          this.transactionFees = new TransactionFees(res.data.transactionFees);
          this.amount = StringHelper.toMajorAmount(
            +this.transactionData.amount + this.transactionFees.WEB_CARD
          );
          const socket = {
            txnRef: res.data.reference,
            txnData: r,
            savedCard: res.data.hasSavedCards,
            transactionFees: new TransactionFees(res.data.transactionFees),
          };

          this.appService.sendData(socket);
          this.setState('isInitialized', true);
          postTransactionMessage('loadedTransaction');
          
          this.initChannelsListener();
          this.initPaymentsListener();
          this.initAccountsListener();
        }
      },
      error: (err: any) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('error', true);
        postTransactionMessage('error', this.errorMessage);
      },
    });
  }

  initChannelsListener() {
    const channelsTab = document.getElementById('payment-form')!;
    const channels = channelsTab.children;
    const activeChannel = Array.from(channels).some((channel) => {
      return channel.classList.contains('active');
    });
    if (!activeChannel && channels.length > 0) {
      channels[0].classList.add('active');
    }
  }

  initPaymentsListener() {
    const paymentTab = document.querySelectorAll('a[data-bs-toggle="tab"]');
    paymentTab.forEach((el) => {
      el.addEventListener('click', (e) => {
        if (this.menuState === 'out') {
          this.menuState = 'in';
        }
        return;
      });
      el.addEventListener('shown.bs.tab', (e) => {
        this.activePaymentTab = (e.target as HTMLInputElement).id;
        this.setTransactionAmount(this.activePaymentTab);
      });
    });

    const activeTabElement = document.querySelector('a[data-bs-toggle="tab"].active');
    if (activeTabElement) {
      this.activePaymentTab = activeTabElement.id;
      this.setTransactionAmount(this.activePaymentTab);
    }
  }

  initAccountsListener() {
    const accountsTab = document.querySelectorAll(
      'button[data-channel="account"]'
    );
    document.getElementById('transfer_channel')?.click();
    accountsTab.forEach((el) => {
      el.addEventListener('shown.bs.tab', (e) => {
        const activeAccountTab = (e.target as HTMLInputElement).id;
        if (activeAccountTab == 'transfer-tab') {
          document.getElementById('transfer_channel')?.click();
        } else {
          document.getElementById('direct-debit_channel')?.click();
        }
      });
    });
  }

  setTransactionAmount(activeTab: string) {    
    switch (activeTab) {
      case 'card-tab':
        this.amount = StringHelper.toMajorAmount(
          +this.transactionData.amount + this.transactionFees.WEB_CARD
        );

        break;
      case 'accounts-tab':
        this.amount = StringHelper.toMajorAmount(
          +this.transactionData.amount + this.transactionFees.WEB_TRANSFER
        );

        break;
      case 'qr-tab':
        this.amount = StringHelper.toMajorAmount(
          +this.transactionData.amount + this.transactionFees.WEB_QR
        );

        break;
      case 'ussd-tab':
        this.amount = StringHelper.toMajorAmount(
          +this.transactionData.amount + this.transactionFees.WEB_USSD
        );

        break;
      case 'google-tab':
      case 'apple-tab':
        this.amount = StringHelper.toMajorAmount(
          +this.transactionData.amount + this.transactionFees.WEB_CARD
        );

        break;

      case 'payattitude-tab':
        this.amount = StringHelper.toMajorAmount(
          +this.transactionData.amount + this.transactionFees.WEB_PAYATTITUDE
        );

        break;

      default:
        this.amount = 0;
        break;
    }
  }

  onHarmBurgerClicked() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  closePopup() {
    postTransactionMessage('close');
    window.addEventListener('message', this.handleEvent);
  }

  onRedirect() {
    this.menuState = 'in';
  }

  paymentCompleted(callbackOptions: any) {
    this.callback = callbackOptions;
    this.setState('completed', true);
    setTimeout(() => {
      this.endTransaction();
    }, 3000);
    
  }

  endTransaction() {
    const response = {
      reference: this.reference,
      message: 'Approved',
      redirecturl: this.callback ? `${this.callback.url}` : null,
      status: 'success',
      metadata: this.transactionData.metadata,
    };
    postTransactionMessage('success', response);
    window.addEventListener('message', this.handleEvent);
    this.setState('completed', false);
    this.setState('error', false);
  }

  reInitialize() {
    this.setState('error', false);
    this.setTransaction(this.transactionData);
  }

  checkChannels(channel: string): boolean {
    channel = channel.toUpperCase();
    
    if (!this.channels || this.channels.length === 0) {
      return true; // Array is empty, so show all channels.
    }    
    return this.channels.includes(channel);
  }

  checkCurrency(): boolean {
    if (this.currency && this.currency == 'USD') {
      this.menuState = 'in';
      return false; // USD is specifed to be the default currency, so hide all other payment channels except cards.
    }
    return true; // currency is not specified, so default to NGN.
  }

  goBack() {}

  ngOnDestroy(): void {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('message', this.handleEvent);
  }
}
