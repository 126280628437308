
<div class="d-flex flex-column justify-content-between ps-3 pe-3">
    <ng-container *ngIf="!state.verifyingQR && !state.error">
      <div class="qr-container">
        <div>
          <div class="qr-container__header">
            <div class="instruction">Scan the QR code below using your financial institution's mobile app to
              complete the payment.
            </div>
            </div>
            <div class="qr__wrapper">
              <div class="qr__code">
                <qrcode
                  [allowEmptyString]="true"
                  [qrdata]="qrCodeValue"
                  [cssClass]="'center'"
                  [elementType]="'img'"
                  [errorCorrectionLevel]="'H'"
                  (qrCodeURL)="onChangeURL($event)"
                  [margin]="4"
                  [scale]="1"
                  [width]="214"
                ></qrcode>
              </div>
            </div>
        </div>
      </div>
      <div class="qr-download">
        <div>
          <div>
            <div class="qr-download">
              <a [href]="qrCodeDownloadLink" class="omni-btn omni-btn-transparent" download="qrImage">
                <span class="d-inline-block ps-2 fz-12 text-underline">Save QR image</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="confirm-payment">
        <button class="omni-btn omni-btn-primary" (click)="confirmTransaction()" [disabled]="error">I have made
          payment</button>
      </div> -->

      <div class="expiry-information">
        <span class="d-inline-block expiry-information__countdown"
          [ngStyle]="{ color: QRExpired ? '#FF435E' : 'var(--primary)' }">
          {{
          QRExpired ? "QR expired" : "QR expires in"
          }}
          &nbsp;
        </span>
        <span style="font-weight: bold; color: var(--primary); margin-right: 10px" *ngIf="!QRExpired">{{ display
          }}</span>
        <span [ngClass]="{ 'error__icon-icon': QRExpired }">
          <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 0H12V2H6V0ZM16.03 6.39L17.45 4.97C17.02 4.46 16.55 3.98 16.04 3.56L14.62 4.98C13.0273 3.69833 11.0443 2.99969 9 3C6.61305 3 4.32387 3.94821 2.63604 5.63604C0.948211 7.32387 0 9.61305 0 12C0 16.97 4.02 21 9 21C10.6943 21.0009 12.3544 20.5232 13.7891 19.622C15.2238 18.7207 16.3748 17.4325 17.1094 15.9057C17.8441 14.379 18.1325 12.6758 17.9415 10.9923C17.7506 9.30874 17.088 7.71341 16.03 6.39ZM10 13H8V7H10V13Z" />
          </svg>
        </span>
      </div>
    </ng-container>
  
    <ng-container *ngIf="state.verifyingQR">
      <div class="verification-loader">
        <span class="omni-spinner-text">{{loadingMessage}}</span>
        <div class="omni-spinner-border" role="status">
        </div>
      </div>
    </ng-container>
    
    <ng-container *ngIf="state.error">
      <div class="omni-checkout-alert">
        <div class="text-center">
          <div class="custom-alert-icon mb-3">
            <svg-icon name="error-icon" svgClass="img-fluid w-50"></svg-icon>
          </div>
          <div class="px-3">
            <div class="fz-14 fw-bold pb-2">
              We could not process this transaction
            </div>
            <div class="w-full text-center pb-2 lh-2">
              <small class="fz-12">
                {{errorMessage}}
              </small>
            </div>
          </div>
          <!-- <div class="mt-4">
                              <button (click)="tryAgain()" class="omni-btn omni-btn-transparent">Try again</button>
                            </div> -->
        </div>
      </div>
    </ng-container>
  </div>