<div class="d-flex flex-column justify-content-between">
  <ng-container *ngIf="!state.verifyingUssd && !state.bankSelected && !state.error">
    <header class="instructions">
      <div class="instructions__message">Pay by dialing a USSD code</div>
    </header>
    <div class="row">
      <div class="form-group col-mb-12 mb-2">
        <div class="group-input">
          <select [(ngModel)]="bankOption" class="form-select omni-form-control" name="bank" aria-label="bank"
            placeholder="Select user bank" (change)="selectBank()" required>
            <option [value]="null" [disabled]="true" [selected]>Select Bank</option>
            <option *ngFor="let option of responseData" [ngValue]="option">{{option.bankName}}</option>
          </select>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!state.verifyingUssd && state.bankSelected && !state.error">
    <ng-container *ngIf="state.visible">
      <div class="shortcode-wrapper">
        <header class="instructions">
          <div class="instructions__message">Dial the code below to complete this transaction with {{bankOption.bankName}}
          </div>
        </header>
        <div class="shortcode">
          <div class="shortcode__content">
            <div class="shortcode__code">
              <div class="shortcode__code-pwt">{{bankOption.bankUssdCode}}</div>
            </div>
            <div class="account-copy">
              <a href="tel:{{bankOption.bankUssdCode}}" class="call-link">
                <span>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.60995 9.39357C11.9342 12.7169 12.6883 8.87218 14.8049 10.9873C16.8454 13.0272 18.0182 13.4359 15.4329 16.0205C15.109 16.2808 13.0515 19.4118 5.82067 12.183C-1.41107 4.95325 1.71815 2.89362 1.97847 2.56988C4.57005 -0.0218765 4.97171 1.15774 7.01224 3.19769C9.1288 5.31366 5.28571 6.07026 8.60995 9.39357Z"
                      stroke="var(--primary)" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span role="button" class="ms-1" style="color: var(--primary);">Dial</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="confirm-payment">
        <button class="omni-btn omni-btn-primary" (click)="confirmTransaction()" [disabled]="error">I have paid {{amount |
          currency:'NGN':'symbol-narrow'}}</button>
      </div> -->
      <div id="cancel-link" (click)="cancelProcess()" class="cancel-link">
        <button type="button" class="cancel-button"> Cancel </button>
      </div>
      <div class="expiry-information">
        <span class="d-inline-block expiry-information__countdown"
          [ngStyle]="{ color: ussdExpired ? '#FF435E' : 'var(--primary)' }">
          {{
          ussdExpired ? "USSD Code expired" : "USSD Code expires in"
          }}
          &nbsp;
        </span>
        <span style="font-weight: bold; color: var(--primary); margin-right: 10px" *ngIf="!ussdExpired">{{ display
          }}</span>
        <span [ngClass]="{ 'error__icon-icon': ussdExpired }">
          <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 0H12V2H6V0ZM16.03 6.39L17.45 4.97C17.02 4.46 16.55 3.98 16.04 3.56L14.62 4.98C13.0273 3.69833 11.0443 2.99969 9 3C6.61305 3 4.32387 3.94821 2.63604 5.63604C0.948211 7.32387 0 9.61305 0 12C0 16.97 4.02 21 9 21C10.6943 21.0009 12.3544 20.5232 13.7891 19.622C15.2238 18.7207 16.3748 17.4325 17.1094 15.9057C17.8441 14.379 18.1325 12.6758 17.9415 10.9923C17.7506 9.30874 17.088 7.71341 16.03 6.39ZM10 13H8V7H10V13Z" />
          </svg>
        </span>
      </div>
      <!-- <div class="w-full text-center pt-4">
        <div *ngIf="!ussdExpired" style="color: #677684" class="fz-12">
          USSD Code will expire in
          <span class="fw-sbold" *ngIf="display; else loading">{{ display }}</span>
          <ng-template #loading> Loading... </ng-template>
        </div>
        <div *ngIf="ussdExpired" class="text-danger fz-12 fw-sbold">USSD Code expired</div>
        <a class="fz-14 code-resend-link"> Resend Now </a>
      </div> -->
    </ng-container>
  </ng-container>
  <ng-container *ngIf="state.verifyingUssd">
    <div class="verification-loader">
      <span class="omni-spinner-text">{{loadingMessage}}</span>
      <div class="omni-spinner-border" role="status">
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="state.error">
    <div class="omni-checkout-alert">
      <div class="text-center">
        <div class="custom-alert-icon mb-3">
          <svg-icon name="error-icon" svgClass="img-fluid w-50"></svg-icon>
        </div>
        <div class="px-3">
          <div class="fz-14 fw-bold pb-2">
            We could not process this transaction
          </div>
          <div class="w-full text-center pb-2 lh-2">
            <small class="fz-12">
              {{errorMessage}}
            </small>
          </div>
        </div>
        <!-- <div class="mt-4">
                            <button (click)="tryAgain()" class="omni-btn omni-btn-transparent">Try again</button>
                          </div> -->
      </div>
    </div>
  </ng-container>
</div>