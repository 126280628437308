<div class="d-flex flex-column justify-content-between">
  <ng-container *ngIf="!state.error">
    <form autocomplete="off" [formGroup]="payattitudeForm" (ngSubmit)="onSubmitBtnClicked()">
      <div class="row">
        <div class="form-group col-mb-12 mb-2">
          <div class="group-input mb-4">
            <label for="phone" class="omni-label">Phone Number</label>
            <div class="tel-combined-input">
              <div class="combined-input-left">
                <svg-icon name="ngaFlag" svgClass="img-fluid w-50"></svg-icon>
                <span class="d-block" style="margin-left: 4px;">+234</span>
              </div>
              <input (keypress)="checkDigit($event)" class="omni-form-control payattitude" minlength="10" maxlength="10"
                id="phone" name="phone" type="tel" placeholder="00 000 0000" formControlName="phone" required />
            </div>
          </div>
        </div>
        <div class="form-group col-md-12 mt-2">
          <div class="group-input">
            <button class="omni-btn omni-btn-primary omni-btn-block" type="submit" [disabled]="!payattitudeForm.valid"
              [ngClass]="{ 'omni-btn-loading': loading }">
              <span>Pay {{ amount ? (amount | currency : transactionData.currency : "symbol-narrow") : "" }}
              </span>
            </button>

            <span></span>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="state.error">
    <div class="omni-checkout-alert">
      <div class="text-center">
        <div class="custom-alert-icon mb-3">
          <svg-icon name="error-icon" svgClass="img-fluid w-50"></svg-icon>
        </div>
        <div class="px-3">
          <div class="fz-14 fw-bold pb-2">
            We could not process this transaction
          </div>
          <div class="w-full text-center pb-2 lh-2">
            <small class="fz-12">
              {{errorMessage}}
            </small>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>