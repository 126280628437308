// Message port for an Android WebView
let nativePort: MessagePort | null = null;

// Inform TypeScript about the existence of the webkit property
let webkitBrowser = window as Window & { webkit?: any };


export function listenForMobileMessagePort() {
  window.addEventListener('message', e => {
    if (e.data) {
      try {
        const data = JSON.parse(e.data);
        if (data && data.type === 'init_port') {
          nativePort = e.ports[0];
        }
      } catch (err) {
        // do nothing
      }
    }
  });
}

function sendToNativePort(data: any) {
  if (nativePort !== null) {
    nativePort.postMessage(JSON.stringify(data));
  }
}

/* eslint-disable no-unused-expressions */
export function onNewTransactionEvent(callback: any) {
  window.addEventListener('message', event => {
    const isNewTransactionEvent = typeof event.data === 'object' && event.data.type === 'omni:url';
    if (isNewTransactionEvent) {
      callback(event);
    }
  });
}

export function onAnalyticsEvent(callback: any) {
  window.addEventListener('message', event => {
    const isAnalyticsEvent = typeof event.data === 'object' && event.data.type === 'analytics';
    const { action, params } = event.data;
    if (isAnalyticsEvent) {
      callback({ action, params });
    }
  });
}

export function postTransactionMessage(type: any, value?: any) {
  const isiOSWebkit = webkitBrowser?.webkit?.messageHandlers;
  const actions: {
    [key: string]: (arg?: any) => void
  } = {
    loadedCheckout() {
      parent?.postMessage('loaded:checkout', '*'); // for backward compatibility
      parent?.postMessage({ event: 'loaded:checkout' }, '*');
      if (isiOSWebkit) {
        webkitBrowser.webkit.messageHandlers?.iosListener?.postMessage({
          event: 'loaded:checkout',
        });
      }
      sendToNativePort({ event: 'loaded:checkout' });
    },
    loadedTransaction(transaction = {}) {
      const { id, customer, accessCode } = transaction;
      parent?.postMessage('loaded:transaction', '*'); // for backward compatibility
      parent?.postMessage({ event: 'loaded:transaction', data: { id, customer, accessCode } }, '*');
      if (isiOSWebkit) {
        webkitBrowser.webkit.messageHandlers?.iosListener?.postMessage({
          event: 'loaded:transaction',
        });
      }
      sendToNativePort({
        event: 'loaded:transaction',
        data: { id, customer, accessCode },
      });
    },
    close() {
      parent?.postMessage('close', '*'); // for backward compatibility
      parent?.postMessage({ event: 'close' }, '*');
      if (isiOSWebkit) {
        webkitBrowser.webkit.messageHandlers?.iosListener?.postMessage({
          event: 'close',
        });
      }
      sendToNativePort({ event: 'close' });
    },
    error(errorObj: any) {
      const eventData = typeof errorObj === "string" || errorObj instanceof String
        ? { event: 'error', data: errorObj }
        : { event: 'error', errorObj };
    
      const messageData = typeof errorObj === "string" ? 'error' : JSON.stringify(errorObj);
    
      parent?.postMessage(messageData, '*'); // for backward compatibility
      if (isiOSWebkit) {
        webkitBrowser.webkit.messageHandlers?.iosListener?.postMessage(eventData);
      }
      sendToNativePort(eventData);
    },
    // handle pay with transfer pending event
    bankTransferConfirmationPending() {
      parent?.postMessage('transfer:pending', '*'); // for backward compatibility
      parent?.postMessage({ event: 'transfer:pending' }, '*');
      if (isiOSWebkit) {
        webkitBrowser.webkit.messageHandlers?.iosListener?.postMessage({
          event: 'transfer:pending',
        });
      }
      sendToNativePort({ event: 'transfer:pending' });
    },
    success(data: any) {
      parent?.postMessage(JSON.stringify(data), '*'); // for backward compatibility
      parent?.postMessage({ event: 'success', data }, '*');
      if (isiOSWebkit) {
        webkitBrowser.webkit.messageHandlers?.iosListener?.postMessage({
          event: 'success',
          data,
        });
      }
      sendToNativePort({ event: 'success', data });
    },
    redirecting() {
      parent?.postMessage('checkout:redirecting', '*'); // for backward compatibility
      parent?.postMessage({ event: 'checkout:redirecting' }, '*');
      if (isiOSWebkit) {
        webkitBrowser.webkit.messageHandlers?.iosListener?.postMessage({
          event: 'checkout:redirecting',
        });
      }
      sendToNativePort({ event: 'checkout:redirecting' });
    }
  };
  if (actions[type] && window.parent) {
    actions[type](value);
  }
}
