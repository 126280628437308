import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
import { ApiResponse } from "./api-response";
import { EMPTY } from "rxjs";

/**
 * Created by Stephen on 20/09/2022.
 */
export class StringHelper {
  public isValidString = StringHelper.isValidString;

  public static toUrlString(obj: Object): string {
    let urlString = '';
    let prop: keyof Object;
    for (prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (obj[prop] != null && obj[prop].constructor === Array) {
          const keys = Object.values(prop);
          keys.forEach((i: any) => {
            urlString += `${prop}=${i}&`;
          });
        } else {
          urlString += prop + '=' + obj[prop] + '&';
        }
      }
    }
    return urlString.substr(0, urlString.length - 1);
  }

  public static hasErrors(error: ApiResponse) {
    return Boolean(
      error.hasOwnProperty('errors') &&
        error['errors'] &&
        error['errors'].length > 0 &&
        error['errors'][0].message
    );
  }

  public static getErrorMessage(
    error: ApiResponse,
    defaultMessage: string = 'Unable to complete request.'
  ): string {
    if (
      this.hasErrors(error) &&
      this.isErrorUserFriendly(error.errors[0].message)
    ) {
      return error.errors[0].message || 'An error occured';
    } else {
      return defaultMessage;
    }
  }

  public static getErrorCode(
    error: ApiResponse,
    defaultCode: string = '000'
  ): string {
    if (
      this.hasErrors(error) &&
      this.isErrorUserFriendly(error.errors[0].code)
    ) {
      return error.errors[0].code || 'PAY_ENG_000';
    } else {
      return defaultCode;
    }
  }

  private static isErrorUserFriendly(err: string) {
    const exceptions = ErrorExceptions;
    const hasException = exceptions.filter(
      (ex) => err.toLowerCase().indexOf(ex) !== -1
    );

    if (hasException && hasException.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  public static isValidString(val: any): boolean {
    return val != null && val !== undefined && val.toString().length > 0;
  }

  public static isValidStrings(...values: string[]): boolean {
    let isValid = true;
    if (values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        if (!StringHelper.isValidString(values[i])) {
          isValid = false;
          break;
        }
      }
    } else {
      isValid = false;
    }

    return isValid;
  }

  public static randomStrings() {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * This enhances the supplied object with methods in this class. This enables you to use
   * the methods in your component's html without referencing the StringHelper.
   * @param obj Object to be decorated, expecting a component.
   */
  public static decorate(obj: any) {
    const methodKeys = Object.keys(this);
    methodKeys.forEach((method) => {
      if (method !== 'decorate' && !obj.hasOwnProperty(method)) {
        obj[method] = this[method as keyof Object];
      }
    });
  }

  public static formatConcatenatedString(concatString: string): string {
    let formattedString = '';
    if (concatString) {
      concatString.split('_').forEach((word) => {
        const lowerCaseString: string = word.toLowerCase();
        formattedString +=
          lowerCaseString.charAt(0).toUpperCase() +
          lowerCaseString.slice(1) +
          ' ';
      });
    }
    return formattedString;
  }

  public static toTitleCase(str: string): string {
    if (str) {
      return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    }
    return str;
  }

  public static decodeJWT(token: string) {
    const base64Url = token.split('.')[1];
    if (base64Url) {
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    } else {
      return undefined;
    }
  }

  public static queryStringToJSON(qs: string) {
    qs = qs || location.search.slice(1);

    var pairs = qs.split('&');
    var result: any = {};
    pairs.forEach(function (p) {
      var pair = p.split('=');
      var key = pair[0];
      var value = decodeURIComponent(pair[1] || '');

      if (result[key]) {
        if (Object.prototype.toString.call(result[key]) === '[object Array]') {
          result[key].push(value);
        } else {
          result[key] = [result[key], value];
        }
      } else {
        result[key] = value;
      }
    });

    return JSON.parse(JSON.stringify(result));
  }

  public static randomId() {
    for (
      var t = '',
        e = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
        n = 0;
      n < 5;
      n++
    )
      t += e.charAt(Math.floor(Math.random() * e.length));
    return t;
  }

  public static getInvalidAmountMessage(message: string): string {
    if (message && message.includes('UNDERPAYMENT')) {
      return 'The payment failed due to underpayment';
    } else {
      return 'The payment failed due to overpayment';
    }
  }

  public static isValid(t: any) {
    if ((this.validateInputTypes(t), null == t.key))
      throw new Error('Please provide your public key via the key attribute');
    if (null == t.amount && null == t.plan)
      throw new Error(
        'Please provide transaction amount via the amount or plan attribute'
      );
    if (null == t.email && null == t.customer_code)
      throw new Error(
        'Please provide customer email via the email or customerCode attribute'
      );
    if (t.transaction_charge && t.transaction_charge >= t.amount)
      throw new Error(
        'Transaction charge must be less than the transaction amount'
      );
    if (t.bearer && 'account' != t.bearer && 'subaccount' != t.bearer)
      throw new Error('Bearer should be either account or subaccount');
    if (t.channels && !t.channels.length)
      throw new Error('Channels should be an array of [card, bank] values');
    if (
      t.customButton &&
      null != t.customButton &&
      null == document.getElementById(t.customButton)
    )
      throw new Error(
        'Please ensure a button with id ' + t.customButton + ' is defined'
      );
    if (
      t.container &&
      null != t.container &&
      null == document.getElementById(t.container)
    )
      throw new Error(
        'Please ensure an element with id ' + t.container + ' is defined'
      );
    return !0;
  }

  public static validateInputTypes(t: any) {
    var n: any = {
      email: 'email',
      amount: 'integer',
      transaction_charge: 'integer',
      invoice_limit: 'integer',
      onClose: 'function',
      callback: 'function',
      metadata: 'object',
      channels: 'array',
    };
    const r = (t: any, e: any) => {
      if (n[t] && e)
        switch (n[t]) {
          case 'email':
            this.isValidEmail(e) || i(t);
            break;
          case 'integer':
            this.isNormalInteger(e) || i(t);
            break;
          case 'function':
            this.isFunction(e) || i(t);
            break;
          case 'object':
            this.isObject(e) || i(t);
            break;
          case 'array':
            this.isArray(e) || i(t);
        }
    };
    for (var e in t) {
      r(e, t[e]);
    }
    function i(t: any) {
      throw new Error('Attribute ' + t + ' must be a valid ' + n[t]);
    }
  }

  public static checkForParentForm(t: any) {
    if ('FORM' == t.parentElement.tagName) return !0;
    throw new Error(
      'Please put your Paystack Inline javascript file inside of a form element'
    );
  }

  public static hasDataAttribute(t: any) {
    var e = !1,
      n = t.attributes;
    for (const key in (n = Array.prototype.slice.call(n))) {
      var r = n[key].nodeName;
      r && -1 < r.indexOf('data') && (e = !0);
    }
    return e;
  }

  public static noBrowserIframeSupport() {
    var t = 'onload' in document.createElement('iframe');
    return (
      t ||
        console.warn(
          'This browser does not support iframes. Please redirect to standard'
        ),
      !t
    );
  }

  public static parseResponse(t: any, e: any) {
    var n, r, i, a, o;
    return (
      'string' == typeof t && (n = t.split(' ')[0]),
      n &&
        ((i = (r = t.split(' '))[1]),
        (a = r.slice(2).join(' ')),
        (o = e.id == i)),
      { action: n, isThisIframe: o, data: a }
    );
  }

  public static omitKeys(t: any, e: any) {
    for (var n = JSON.parse(JSON.stringify(t)), r = 0; r < e.length; r++)
      delete n[e[r]];
    for (var i in n) n.hasOwnProperty(i) && !n[i] && delete n[i];
    return n;
  }

  public static isObject(t: any) {
    return (
      t === Object(t) && '[object Array]' !== Object.prototype.toString.call(t)
    );
  }

  public static isArray(t: any) {
    return t.constructor === Array;
  }

  public static isEmptyArray(t: any[]) {
    return t && t.length == 0;
  }

  public static isNormalInteger(t: any) {
    return parseInt(t) == t && 0 <= t;
  }

  public static isFunction(t: any) {
    if (!t) return !1;
    return t && '[object Function]' === {}.toString.call(t);
  }

  public static isValidEmail(t: any) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      t
    );
  }

  public static getHref() {
    var t = window.location.href;
    return t && 500 < t.length && (t = t.split('?')[0]), t;
  }

  public static findHighestZIndex(t: any) {
    for (
      var e = document.getElementsByTagName(t), n = 0, r = 0;
      r < e.length;
      r++
    ) {
      var i = document
        .defaultView!.getComputedStyle(e[r], null)
        .getPropertyValue('z-index');
      (n as any) < i && 'auto' != i && ((n as any) = i);
    }
    return parseInt(n as any);
  }

  public static toMajorAmount(amount: any): any {
    if (amount !== 0 && (!amount || isNaN(amount))) {
      return '';
    } else {
      return parseFloat(amount) / 100;
    }
  }

  public static toMinorAmount(amount: number): number {
    return +(amount * 100).toFixed();
  }

  public static formatCardPan(value: string, maxAllowedLength: number) {
    // Remove spaces from the input value
    const newValue = value.replace(/\s+/g, '');
    // Limit the input to the calculated length;
    return newValue.slice(0, maxAllowedLength);
  }

  public static removeSpaces(value: string) {
    return value.replace(/\s/g, '');
  }

  public static copyToClipboard = async ({
    target,
    message,
    value,
  }: ICopyToClipboard) => {
    try {
      let copyValue = '';

      if (!navigator.clipboard) {
        throw new Error("Browser don't have support for native clipboard.");
      }

      if (target) {
        const node = document.getElementById(target);

        if (!node || !node.textContent) {
          throw new Error('Element not found');
        }

        value = node.textContent;
      }

      if (value) {
        copyValue = value;
      }

      await navigator.clipboard.writeText(copyValue);
    } catch (error) {
      console.log(error);
    }
  };

  public static encryptFun(value: any) {
    var data = value;
    var key = CryptoJS.enc.Latin1.parse(environment.secretKey);
    var iv = CryptoJS.enc.Latin1.parse(environment.secretKey);
    var encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
    return encrypted.toString();
    // var decrypted = CryptoJS.AES.decrypt(encrypted,key,{iv:iv,padding:CryptoJS.pad.ZeroPadding});
  }

  public static fetchTypeByValue(sortData: any, value: string) {
    if (!sortData) {
      return EMPTY;
    }

    const data = sortData.filter((e: any) => {
      return e.name === value;
    });

    return data.length > 0 ? data[0] : null;
  }
}

export const ErrorExceptions = [
  'exception',
  'null',
  'springboot',
  'exceptions',
  'resultset',
  'sql',
  'hibernate',
];

// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard

/**
 * Interface CopyToClipboard params
 */
 export interface ICopyToClipboard {
  /** HTML reference identifier ```<div id="foo"></div>```  */
  target?: string;
  /** String value */
  value?: string;
  /** (Optional) message to display in snackbar on success */
  message?: string;
}

export enum PaymentProcessor {
  MPGS,
  IPG,
  CYBS
}