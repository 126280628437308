import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TransactionData } from '../api-request';
import { TransactionFees } from '../api-response';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AppService } from '../app.service';
import { StringHelper } from '../strings-helper';
import { postTransactionMessage } from '../events';

@Component({
  selector: 'payattitude-channel',
  templateUrl: './payattitude-channel.component.html',
  styleUrls: ['./payattitude-channel.component.scss'],
})
export class PayattitudeChannelComponent implements OnInit {
  @Input() activeTab: any = null;
  @Output() onComplete: any = new EventEmitter<any>();
  @Output() onClose: any = new EventEmitter<any>();
  error: boolean = false;
  loading: boolean = false;
  payattitudeForm: FormGroup;

  state = {
    visible: false,
    isInitialized: false,
    completed: false,
    loaded: false,
    isFilling: false,
    verifyingPayattitude: false,
    error: false,
  };
  responseData: any;
  bankOption: any = null;
  transactionData!: TransactionData;
  amount: any;
  currency: any;
  transactionFees!: TransactionFees;
  reference!: string;
  errorMessage!: string;
  callback: any = {};

  private setState(key: any, value: any) {
    this.state = { ...this.state, [key]: value };
    this.cd.detectChanges();
  }

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private el: ElementRef
  ) {
    this.payattitudeForm = this.formBuilder.group({
      phone: new FormControl('', [
        Validators.required,
        this.numericValidator(),
      ]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.state.visible) {
      if (changes['activeTab'].currentValue == 'payattitude-tab') {
        this.setState('isFilling', true);
        this.setState('error', false);
      }
    }
  }

  checkDigit(event: any) {
    const code = event.which ? event.which : event.keyCode;

    if ((code < 48 || code > 57) && code > 31) {
      return false;
    }

    return true;
  }

  ngAfterViewInit(): void {
    this.appService.getData().subscribe({
      next: (res) => {
        this.transactionData = res.txnData;
        this.reference = res.txnRef;
        this.transactionFees = res.transactionFees;
        this.amount = StringHelper.toMajorAmount(
          +this.transactionData.amount + this.transactionFees.WEB_PAYATTITUDE
        );
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  ngOnInit(): void {}

  paymentDone() {
    this.onComplete.emit(this.callback);
    const domEvent = new CustomEvent('paymentDone');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  paymentFailed() {
    const error = {
      reference: this.reference,
      message: this.errorMessage,
      status: 'failure',
    };
    postTransactionMessage('error', error);
    const domEvent = new CustomEvent('paymentFailed');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  numericValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isNumeric = /^[0-9]+$/.test(control.value);
      return isNumeric ? null : { nonNumeric: true };
    };
  }

  onSubmitBtnClicked() {
    this.loading = true;
    this.setState('verifyingPayattitude', true);
    const formattedPhone = this.payattitudeForm.value?.phone.padStart(
      this.payattitudeForm.value?.phone?.length + 1,
      '0'
    );
    let request = {
      requestType: 'PAY_WITH_PAYATTITUDE',
      phoneNumber: formattedPhone,
      transactionRef: this.reference,
    };
    this.appService.payattitudeRequest(request).subscribe({
      next: (res) => {
        console.log(res);
        this.loading = false;
        this.setState('isFilling', false);
        this.setState('verifyingPayattitude', false);
        this.paymentDone();
      },
      error: (error) => {
        this.errorMessage = StringHelper.getErrorMessage(error.error);
        this.setState('verifyingPayattitude', false);
        this.setState('error', true);
        this.paymentFailed();
        this.loading = false;
      },
    });
  }
}
