import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { timer } from 'rxjs';
import { TransactionData } from 'src/app/api-request';
import { ApiResponse, TransactionFees } from 'src/app/api-response';
import { AppService } from 'src/app/app.service';
import { postTransactionMessage } from 'src/app/events';
import { StringHelper } from 'src/app/strings-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'nibss-qr-channel',
  templateUrl: './nibss-qr-channel.component.html',
  styleUrls: ['../qr-channel.component.scss']
})
export class NibssQrChannelComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Output() onComplete: any = new EventEmitter<any>();
  @Output() onClose: any = new EventEmitter<any>();
  @Input() activeTab: any = null;

  verifyingQR: boolean = false;
  inProcess = true;
  public qrCodeValue: string = '';
  public qrCodeDownloadLink: SafeUrl = '';

  state = {
    visible: false,
    qrTypeSelected: false,
    error: false,
    verifyingQR: false
  };
  responseData: any;
  transactionData!: TransactionData;
  amount: any;
  transactionFees!: TransactionFees;
  reference!: string;
  errorMessage!: string;

  time_in_minutes = 10;

  display: string = `${10} minutes`;
  public timerInterval: any;
  QRExpired: boolean = false;
  loadingMessage: string = 'Please wait';
  queryTime: number = (environment.txnQueryTime/2); // 10 minutes

  private setState(key: any, value: any) {
    this.state = { ...this.state, [key]: value };
    this.cd.detectChanges();
  }

  private timer$: any;
  callback: any = {};

  constructor(
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private el: ElementRef
    ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.state.visible) {
      if (changes['activeTab'].currentValue == 'qr-tab') {
        this.generateQrCode(this.transactionData);
      }
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.appService.getData().subscribe({
      next: (res) => {
        this.transactionData = res.txnData;
        this.reference = res.txnRef;
        this.transactionFees = res.transactionFees
        this.amount = StringHelper.toMajorAmount(+this.transactionData.amount + this.transactionFees.WEB_QR);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  startCountDown() {
    this.QRExpired = false;
    this.timer(this.time_in_minutes);
  }

  stopCountDown() {
    clearInterval(this.timerInterval);
  }

  timer(minute: number) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.QRExpired = true;
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  generateQrCode(r: TransactionData) {
    this.setState('verifyingQR', true);
    this.setState('error', false);
    const data: any = {
      transactionReference: this.reference,
      qrRequestType: 'GENERATE_DYNAMIC_NQR'
    };
    this.appService.generateQRCode(data).subscribe({
      next: (res) => {
        if (res.success) {
          this.setState('verifyingQR', false);
          this.setState('visible', true);
          this.qrCodeValue = res.data.qrCodeUrl
          this.listenForTransactionStatus();
          this.startCountDown();
        } else {
          this.setState('verifyingQR', false);
          this.setState('error', true);
          this.errorMessage =
            'An error occurred while generating QR, please try again';
        }
        // notify visa-qr component of nqr request complete
        // this.appService.signalRequestComplete();
      },
      error: (err) => {
        console.log(err);
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingQR', false);
        this.setState('error', true);
        this.stopCountDown();
        // notify visa-qr component of nqr request complete
        // this.appService.signalRequestComplete();
      },
    });
  }

  listenForTransactionStatus() {
    this.beginQuery();
  }

  confirmTransaction() {
    this.setState('verifyingQR', true);
    var qrReq: any = {
      transactionReference: this.reference,
      qrRequestType: 'NQR_STATUS'
    };
    this.appService.queryQRransaction(qrReq).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          this.setState('verifyingQR', false);
          this.callback.url = res.data.redirectHtml;
          this.paymentDone();
        } else {
          const errorMessage = StringHelper.getErrorMessage(res);
          if (errorMessage == "PENDING_CONFIRMATION") {
            this.beginQuery();
            return;
          }
          this.setState('verifyingQR', false);
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingQR', false);
        this.setState('error', true);
        this.paymentFailed();
      },
    });
  }

  beginQuery() {
    this.startInterval();
  }

  startInterval() {
    this.timer$ = timer(0, 10000)
    .subscribe((tick: any) => {
      this.loadingMessage = `Confirming Transaction Status.`
      if (tick == this.queryTime) {
        this.loadingMessage = `It's taking longer than expected to confirm your payment.`
        this.errorMessage = `We could not confirm your payment.`
        setTimeout(() => {
          this.setState('verifyingQR', false);
          this.setState('error', true);
          this.paymentFailed();
        }, 3000);
      }
      else if (tick == this.queryTime + 2) {
        this.stopInterval();
        // this.onClose.emit();
      }
      else {
      // Your API call, which will be performed every 10000 milliseconds
        this.queryTransaction();
      }
    });
  }

  stopInterval() {
    this.timer$.unsubscribe();
  }

  queryTransaction() {
    var qrReq: any = {
      transactionReference: this.reference,
      qrRequestType: 'NQR_STATUS'
    };
    this.appService.queryQRransaction(qrReq).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          const isSuccessful = res.data.serviceResponseCodes == "COMPLETED";
          if (isSuccessful) {
            this.setState('verifyingQR', false);
            this.paymentDone();
          }
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingQR', false);
        this.setState('error', true);
        this.paymentFailed();
      },
    });
  }

  verifyQR() {
    this.verifyingQR = true;
    setTimeout(() => {
      this.verifyingQR = false;
      this.inProcess = false;
    }, 2000);
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  paymentDone() {
    this.onComplete.emit(this.callback);
    const domEvent = new CustomEvent('paymentDone');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  paymentFailed() {
    const error = {
      reference: this.reference,
      message: this.errorMessage,
      status: 'failure'
    };
    postTransactionMessage('error', error);
    const domEvent = new CustomEvent('paymentFailed');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  ngOnDestroy(): void {
    this.timer$?.unsubscribe();
  }

}
