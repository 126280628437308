import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseUrl } from './baseurl';
import { StringHelper } from './strings-helper';
import { TransactionData } from './api-request';
import { environment } from 'src/environments/environment';

/**
 * Created by Stephen on 17/09/2022.
 */
@Injectable({
  providedIn: 'root',
})
export class AppService extends BaseUrl {
  webEngineBaseUrl = '/api/v1/web-engine/process';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  private subject = new BehaviorSubject<any>(null);
  private requestCompleteSubject = new Subject<void>();
  transactionData!: TransactionData;
  customTermUrl: string = `${this.BASE_URL}/api/v1/web-engine/process/cardinal-callback`;
  thirdPartyBaseUrl = 'https://api.countrystatecity.in/v1';

  constructor(private http: HttpClient) {
    super();
  }

  private buildRequestHeaders(): void {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    headers = headers.set('Api-Public-Key', this.processData().key);

    this.httpOptions.headers = headers;
  }

  private buildExternalRequestHeader(): void {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    headers = headers.set('X-CSCAPI-KEY', environment.X_CSCAPI_KEY);

    this.httpOptions.headers = headers;
  }

  //PAYMENT INITIALIZATION

  initializeTransaction(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url =
      this.BASE_URL + this.webEngineBaseUrl + '/transaction-initialization';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  //PROFILE REFERENCE
  profileUser(request: any): Observable<any> {
    request = StringHelper.toUrlString(request);
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + `/profiling?${request}`;
    return this.http.post<any>(url, null, this.httpOptions);
  }

  //CARD TRANSACTIONS

  initiateCardPayment(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/card-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  authenticateCardPayment(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/card-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  enrollPayer(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/card-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  confirmCardPayment(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/card-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  fetchSavedCards(request: any): Observable<any> {
    this.buildRequestHeaders();
    request = StringHelper.toUrlString(request);
    const url =
      this.BASE_URL +
      this.webEngineBaseUrl +
      `/fetch-customer-cards?${request}`;
    return this.http.get<any>(url, this.httpOptions);
  }

  confirmTokenizedCardPayment(request: any): Observable<any> {
    // this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/card-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  //USSD TRANSACTIONS

  generateUssdCode(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/ussd-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  queryUssdTransaction(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/ussd-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  queryTransactions(eventType: string, reference: string): Observable<any> {
    this.buildRequestHeaders();
    const url = `${
      this.BASE_URL + this.webEngineBaseUrl
    }/${eventType}-status?transactionRef=${reference}`;
    return this.http.get<any>(url, this.httpOptions);
  }

  //VIRTUAL ACCOUNT TRANSACTIONS

  generateVirtualAccount(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url =
      this.BASE_URL + this.webEngineBaseUrl + '/virtual-account-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  transferPaymentStatus(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url =
      this.BASE_URL + this.webEngineBaseUrl + '/virtual-account-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  //QR TRANSACTIONS

  generateQRCode(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/qr-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  queryQRransaction(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/qr-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  //APPLE PAY TRANSACTIONS
  verifyMerchant(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/apple/create-session';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  // PAYATTITUDE TRANSACTIONS
  payattitudeRequest(request: any): Observable<any> {
    this.buildRequestHeaders();
    const url = this.BASE_URL + this.webEngineBaseUrl + '/payattitude-request';
    return this.http.post<any>(url, request, this.httpOptions);
  }

  //MISC

  sendData(data: any) {
    this.subject.next(data);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  processData() {
    // Process the received data
    return this.transactionData;
  }

  getRequestCompleteObservable() {
    return this.requestCompleteSubject.asObservable();
  }

  signalRequestComplete() {
    this.requestCompleteSubject.next();
  }

  getCountries(): Observable<any> {
    this.buildExternalRequestHeader();
    const url = `${this.thirdPartyBaseUrl}/countries`;
    return this.http.get(url, this.httpOptions);
  }

  getStates(countryIso: string): Observable<any> {
    this.buildExternalRequestHeader();
    const url = `${this.thirdPartyBaseUrl}/countries/${countryIso}/states`;
    return this.http.get(url, this.httpOptions);
  }
}
